import useSignalPath from '@/hooks/useSignalPath'
import { generateBeyondImageLoader } from '@/lib/utils'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Link,
  Spacer,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'
import GalleryIcon from 'icons/gallery.svg'
import VideoIcon from 'icons/video.svg'
import VirtualIcon from 'icons/virtual.svg'
import Image from 'next/image'
import NextLink from 'next/link'
import React from 'react'
import { Carousel } from '../core/Carousel'
import ReactPlayer from 'react-player'

const DisplayImageOverlay = ({ extraImages, borderRadius }) => (
  <Box
    position="absolute"
    cursor="pointer"
    zIndex={3}
    bgColor="white"
    color="gray.800"
    px="30px"
    py="8px"
    borderRadius={borderRadius}
    maxHeight="40px"
    borderColor="gray.800"
    borderWidth="1px"
    bottom={{ base: '5px', lg: '16px' }}
    right={{ base: '5px', lg: '19px' }}
  >
    <Text zIndex="0" fontWeight="bold" fontSize={{ base: '11px', lg: '14px' }}>
      + View {extraImages} photos
    </Text>
  </Box>
)

const DisplayImage = ({
  i = 0,
  selectedImage,
  total,
  image,
  extraImages,
  beyondImageLoader,
  borderRadius,
  onClick,
  priority,
  isVideoTourGallery
}) => {
  // Load only next and previous image
  const distance = Math.min(
    Math.abs(selectedImage - i),
    total - Math.abs(selectedImage - i)
  )

  const displayOverlay = isVideoTourGallery ? (i === 3 && !!extraImages) : (i === 4 && !!extraImages)

  return (
    // Mantain desired aspect ratio
    <Box
      pt="66.66%"
      role="button"
      key={i}
      bg="gray.100"
      h="full"
      position="relative"
      cursor="pointer"
      onClick={() => onClick(i)}
    >
      {displayOverlay && (
        <DisplayImageOverlay
          extraImages={extraImages}
          borderRadius={borderRadius}
        />
      )}
      <Image
        loader={beyondImageLoader}
        layout="fill"
        alt={image.alt_text}
        src={image.full_url}
        priority={priority || distance <= 1}
        style={{
          userSelect: 'none',
          borderRadius
        }}
      />
    </Box>
  )
}

const SingleImage = ({ image, onClick, beyondImageLoader }) => (
  <Flex pos="relative" h={469} onClick={() => onClick(0)} role="button">
    <Image
      loader={beyondImageLoader}
      layout="fill"
      alt={image.alt_text}
      src={image.full_url}
      objectFit="cover"
      objectPosition="center"
      style={{
        userSelect: 'none',
        objectFit: 'cover', // props are removed in v13
        objectPosition: 'center',
        borderRadius: '5px'
      }}
    />
  </Flex>
)

const DisplayVideoTour = ({ video_url }) => {
  const [isPlaying, setIsPlaying] = React.useState(false)
  if (video_url) {
    return (
      <div style={{borderRadius: '5px', overflow: 'hidden', width: '100%', height: '100%'}}>
        <ReactPlayer
          url={video_url}
          width='100%'
          height='100%'
          loop={true}
          playing={isPlaying}
          autoplay={true}
          onMouseOver={() => setIsPlaying(true)}
          onMouseOut={() => setIsPlaying(false)}
          controls
        />
      </div>  
    )
  }

  return null
}

const CustomLink = ({ property, name, displayText, icon }) => {
  const custom_cols = property.custom_cols
  const custom_col = custom_cols.find(function (custom_col) {
    return custom_col.col_name === name
  })
  const isVideoTour = displayText === 'Video Tour'
  if (custom_col && custom_col.col_value) {
    return (
      <Link
        href={custom_col.col_value}
        display="flex"
        alignItems="center"
        // Prevent hydration issue due to word press plugin
        className={isVideoTour ? 'mfp-iframe lightbox-added' : ''}
        isExternal
      >
        <Icon as={icon} w={5} h={5} fill={'gray.500'} mx="2" />
        {displayText}
      </Link>
    )
  }

  return null
}

const GalleryCounter = ({ current, total, ...props }) => {
  return (
    <Flex
      position="absolute"
      bottom="10px"
      right="10px"
      zIndex="2"
      bg="rgba(0,0,0,0.7)"
      paddingY="1"
      paddingX="4"
      borderRadius="5px"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontWeight="bold" color="white" {...props}>
        {current + 1 + ' / ' + total}
      </Text>
    </Flex>
  )
}

export const PropertyImageGallery = ({
  property,
  settings,
  onClick,
  customCols = true,
  showViewAllBtn = true
}) => {
  const signalPath = useSignalPath()
  const galleryUrl = signalPath('gallery')
  const [selectedImage, setSelectedImage] = React.useState(0)
  const maxImages = settings.maxImages ?? 100
  const isMobile = useBreakpointValue({ base: true, md: false })

  const imageSize = isMobile ? 'medium' : 'large'
  const beyondImageLoader = generateBeyondImageLoader(settings, imageSize)
  const displaySize = settings.uploadImageSize[imageSize]

  const custom_cols = property.custom_cols || []
  const videoTourColumn = custom_cols.find(({ col_name }) => col_name === 'video_tour')
  const videoTourUrl = videoTourColumn?.col_value
  const displayVideoInGallery = settings.displayVideoInGallery
  const isVideoTourGallery = !!videoTourUrl && displayVideoInGallery
  const firstImageSize = isVideoTourGallery ? 1 : 2

  const images = [...property.images]
  images.length = images.length > maxImages ? maxImages : images.length
  const displayImages = isMobile ? images.slice(0, 1) : isVideoTourGallery ? images.slice(0, 4) : images.slice(0, 5)
  const extraImages = images.length > 5 ? images.length : 0
  const isSingleImage = images.length === 1
  const imageCount = isVideoTourGallery ? (images.length + 1) : images.length

  return (
    <Box
      px={5}
      pb={5}
      pt={isMobile ? 0 : 5}
      mx="auto"
      maxW={1450}
      position="relative"
    >
      {isMobile ? (
        <Box mx={-5} position="relative">
          <GalleryCounter current={selectedImage} total={imageCount} />
          {isVideoTourGallery ? (
            <Carousel
              showArrows={true}
              infiniteLoop={false}
              selectedImage={selectedImage}
              showIndicators={false}
              onChange={(id) => setSelectedImage(id)}
            >
              <DisplayVideoTour video_url={videoTourUrl} key="video" />
              {images.map((image, i) => (
                <DisplayImage
                  i={i}
                  key={i}
                  image={image}
                  beyondImageLoader={beyondImageLoader}
                  displaySize={displaySize}
                  onClick={onClick}
                  isVideoTourGallery={isVideoTourGallery}
                />
              ))}
            </Carousel>
          ): 
            <Carousel
              showArrows={false}
              infiniteLoop={false}
              selectedImage={selectedImage}
              showIndicators={false}
              onChange={(id) => setSelectedImage(id)}
            >
              {images.map((image, i) => (
                <DisplayImage
                  i={i}
                  key={i}
                  image={image}
                  beyondImageLoader={beyondImageLoader}
                  displaySize={displaySize}
                  onClick={onClick}
                  isVideoTourGallery={isVideoTourGallery}
                />
              ))}
              </Carousel>
          }
        </Box>
      ) : !isSingleImage ? (
        <Grid
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(4, 1fr)"
          gap={4}
          mx={0}
        >
          {isVideoTourGallery && (
            <GridItem colSpan={2} rowSpan={2}>
              <DisplayVideoTour video_url={videoTourUrl} />
            </GridItem>
          )}
          {displayImages.map((image, i) => (
            <GridItem
              key={i}
              colSpan={i === 0 ? firstImageSize : 1}
              rowSpan={i === 0 ? firstImageSize : 1}
            >
              <DisplayImage
                i={i}
                image={image}
                extraImages={extraImages}
                beyondImageLoader={beyondImageLoader}
                displaySize={displaySize}
                borderRadius={isMobile ? '0' : '5px'}
                onClick={onClick}
                priority
                isVideoTourGallery={isVideoTourGallery}
              />
            </GridItem>
          ))}
        </Grid>
      ) : (
        <SingleImage
          beyondImageLoader={beyondImageLoader}
          image={displayImages[0]}
          onClick={onClick}
        />
      )}

      <Flex mt={2}>
        {customCols && (
          <>
            <Spacer />
            {!isVideoTourGallery && 
              <CustomLink
                property={property}
                name="video_tour"
                displayText="Video Tour"
                icon={VideoIcon}
              />
            }
            <CustomLink
              property={property}
              name="virtual_tour"
              displayText="Virtual Tour"
              icon={VirtualIcon}
            />
          </>
        )}
        {images.length > 1 && showViewAllBtn && (
          <NextLink href={galleryUrl} passHref>
            <Link display="flex" alignItems="center">
              <Icon as={GalleryIcon} w={5} h={5} fill={'gray.500'} mx="2" />
              View all images
            </Link>
          </NextLink>
        )}
      </Flex>
    </Box>
  )
}
